<template>
  <div>
    <div class="font-medium text-lg">{{ $t('New Order') }}</div>

    <div v-if="!confirm">
      <div v-for="config in configs" :key="config.key" class="form-control">
        <div :class="config.class" v-if="!config.hidden">
          <label class="cursor-pointer label">
            <span class="label-text">{{ config.label }}</span>
          </label>

          <!-- CHECKBOX -->
          <input
            v-if="config.type === 'CHECKBOX'"
            type="checkbox"
            checked="checked"
            class="toggle border-solid toggle-primary"
            v-model="input[config.key]"
          />

          <!-- SELECT -->
          <select
            v-else-if="config.type === 'SELECT'"
            :disabled="config.disabled"
            class="select select-bordered w-full border-solid"
            v-model="input[config.key]"
          >
            <option
              v-for="{ label, value } in config.options"
              :key="value"
              :value="value"
              v-text="label"
            />
          </select>

          <!-- INPUT -->
          <input
            v-else
            :disabled="config.disabled"
            :type="config.type || 'text'"
            :class="[
              config.disabled
                ? 'input-box-disabled'
                : errors[config.key]
                ? 'input-box-error'
                : 'input-box',
            ]"
            v-model="input[config.key]"
          />
          <label v-if="errors[config.key]" class="input-error-label">
            {{ errors[config.key] }}
          </label>
        </div>
      </div>
    </div>

    <div class="w-64 ml-12" v-else>
      <div class="flex-between flex-inline">
        <span class="font-medium"> {{ $t('Account') }}: </span>
        <span>
          {{ account.name }}
        </span>
      </div>
      <div class="flex-between flex-inline" v-if="input.symbol">
        <span class="font-medium"> {{ $t('Symbol') }}: </span>
        <span>
          {{ input.symbol }}
        </span>
      </div>

      <div class="flex-between flex-inline" v-if="input.productId">
        <span class="font-medium"> {{ $t('Product ID') }}: </span>
        <span>
          {{ input.productId | formatNumber }}
        </span>
      </div>

      <div class="flex-between flex-inline">
        <span class="font-medium"> {{ volumeLabel }}: </span>
        <span>
          {{ input.volume | formatNumber }}
        </span>
      </div>

      <div class="flex-between flex-inline" v-if="!isMarketOrder">
        <span class="font-medium"> PRICE: </span>
        <span>
          {{ input.price | formatNumber }}
        </span>
      </div>
      <div class="flex-between flex-inline">
        <span class="font-medium"> {{ $t('Side') }}: </span>
        <span>
          {{ side }}
        </span>
      </div>

      <div class="flex-between flex-inline">
        <span class="font-medium"> {{ $t('Order Type') }}: </span>
        <span class="font-medium">
          {{ type }}
        </span>
      </div>

      <div class="flex-between flex-inline" v-if="input.orderType !== 1">
        <span class="font-medium"> {{ $t('Span') }}: </span>
        <span class="font-medium"> {{ span }} </span>
      </div>

      <div class="flex-between flex-inline">
        <span class="font-medium"> {{ $t('Reduce Only') }}: </span>
        <span class="font-medium">
          {{ input.reduceOnly ? $t('Yes') : $t('No') }}
        </span>
      </div>
    </div>

    <div class="modal-action">
      <button-group class="space-x-2" :buttons="buttons" />
    </div>
  </div>
</template>

<script>
import { ORDER_SIDES } from '@/common/constants.js'
import { symbols as exchangeSymbols, isCoinBase } from '@/utils/exchange'

const SPAN_OPTIONS = [
  { value: 30e3, label: '30 SECONDS' },
  { value: 60e3, label: '1 MINUTE' },
  { value: 120e3, label: '2 MINUTES' },
  { value: 300e3, label: '5 MINUTES' },
  { value: 600e3, label: '10 MINUTES' },
  { value: 900e3, label: '15 MINUTES' },
  { value: 3600e3, label: 'ONE HOUR' },
]

export default {
  name: 'new-order',

  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    input: {
      orderType: 1,
      symbol: '',
      volume: 0,
      productId: 0,
      stopOrder: false,
      limitOrder: false,
      reduceOnly: false,
      span: 60e3,
      price: 0,
      side: ORDER_SIDES.BUY,
    },
    errors: {
      volume: '',
      price: '',
    },
    confirm: false,
    id: null,
  }),

  computed: {
    isMarketOrder() {
      return this.input.orderType === 1
    },
    exchange() {
      return this.account.exchange
    },
    isBtc() {
      return isCoinBase(this.exchange)
    },
    isLimit() {
      return true
      // return ['omtrader', 'paper'].includes(this.exchange)
    },
    volumeLabel() {
      const unit = this.isBtc ? 'Coin' : 'USD'
      return `${this.$t('Volume')} (${unit})`
    },
    hasProductId() {
      return this.exchange === 'bitcopy'
    },
    side() {
      return this.input.side === ORDER_SIDES.BUY ? 'BUY' : 'SELL'
    },
    span() {
      const map = {
        30e3: '30 SECONDS',
        60e3: '1 MINUTE',
        120e3: '2 MINUTES',
        300e3: '5 MINUTES',
        600e3: '10 MINUTES',
        900e3: '15 MINUTES',
        3600e3: 'ONE HOUR',
      }
      return map[this.input.span]
    },
    type() {
      if (this.isMarketOrder) {
        return this.$t('Market')
      }
      if (this.input.orderType === 2) {
        return this.$t('Stop')
      }
      return this.$t('Limit')
    },
    stopOrder() {
      return !!this.input.stopOrder
    },
    symbols() {
      return exchangeSymbols(this.exchange)
    },
    configs() {
      return [
        {
          key: 'symbol',
          type: 'SELECT',
          label: this.$t('Symbol'),
          required: true,
          options: this.symbols.map((symbol) => ({
            value: symbol,
            label: symbol,
          })),
          hidden: this.symbols.length < 1,
        },
        {
          type: 'SELECT',
          label: this.$t('Order Type'),
          key: 'orderType',
          options: [
            {
              label: 'Market Order',
              value: 1,
            },
            {
              label: 'Stop Order',
              value: 2,
            },
            {
              label: 'Limit Order',
              value: 3,
              hidden: !this.isLimit,
            },
          ].filter((el) => !el.hidden),
        },
        {
          hidden: !this.hasProductId,
          type: 'number',
          disabled: true,
          label: this.$t('ProductID'),
          key: 'productId',
        },
        {
          type: 'SELECT',
          label: this.$t('Span'),
          key: 'span',
          options: SPAN_OPTIONS,
          disabled: this.isMarketOrder,
        },
        {
          label: this.$t('Price'),
          key: 'price',
          type: 'number',
          required: !this.isMarketOrder,
          disabled: this.isMarketOrder,
        },
        {
          label: this.volumeLabel,
          key: 'volume',
          type: 'number',
        },
        {
          type: 'SELECT',
          label: this.$t('Side'),
          key: 'side',
          options: [
            { value: ORDER_SIDES.BUY, label: 'BUY' },
            { value: ORDER_SIDES.SELL, label: 'SELL' },
          ],
        },
        {
          type: 'CHECKBOX',
          label: this.$t('Reduce Only'),
          key: 'reduceOnly',
          class: 'flex-end space-x-2',
        },
      ].filter((el) => !el.hidden)
    },
    buttons() {
      return [
        {
          hidden: this.confirm,
          class: 'btn-sm-warning',
          label: this.$t('Order'),
          handler: () => {
            this.errors.volume = false
            this.errors.price = false
            let error = false
            if (
              this.input.orderType !== 1 &&
              parseFloat(this.input.price) === 0
            ) {
              this.errors.price = this.$t('Price is required')
              error = true
            }

            if (this.hasProductId && parseInt(this.input.productId) < 1) {
              this.errors.productId = this.$t('ProductID is required')
              error = true
            }

            if (this.stopOrder && !this.input.price) {
              this.errors.price = this.$t('Price is required')
              error = true
            }

            if (this.input.volume <= 0) {
              this.errors.volume = this.$t('Volume is required')
              error = true
            }

            this.confirm = !error
          },
        },
        {
          hidden: this.confirm,
          class: 'btn-sm',
          label: this.$t('Close'),
          handler: (event) => this.$$closeModal(event),
        },
        {
          hidden: !this.confirm,
          class: 'btn-sm-warning',
          label: this.$t('Order'),
          handler: this.order,
        },
        {
          hidden: !this.confirm,
          class: 'btn-sm',
          label: this.$t('Edit'),
          handler: () => (this.confirm = false),
        },
      ]
    },
  },

  mounted() {
    this.input.symbol = this.symbols[0]
    this.input.productId = this.account.productId
  },

  methods: {
    async order(event) {
      this.input.volume = parseFloat(this.input.volume)
      this.input.price = parseFloat(this.input.price)
      this.input.credentialId = this.account._id
      const data = this.input
      if (this.isMarketOrder) {
        delete data.price
      } else if (data.orderType === 2) {
        data.stopOrder = true
      } else if (data.orderType === 3) {
        data.limitOrder = true
      }
      delete data.orderType
      await this.$http.post('/api/order/manual', data)
      this.$$closeModal(event)
      this.$router.push({ name: 'manual-orders' })
    },
  },
}
</script>
