import { EXCHANGES } from '@/common/constants.js'
import { headerItem } from '@/utils/table'
import { formatNumber } from '@/utils/string'
import DBtn from '@/components/daisy/atoms/button/index.vue'
import AccountName from './AccountName.vue'
import EditAccount from './EditAccount.vue'
import AccountPosition from './Position.vue'
import Chart from './Chart.vue'

export default function headers(_this) {
  return [
    headerItem('Account Name', 'name', {
      cellClass: 'font-bold sticky left-0',
      generator: (account) => {
        return {
          component: AccountName,
          props: {
            account,
          },
        }
      },
    }),
    headerItem('Chart', 'chart', {
      generator: (account) => {
        return {
          component: Chart,
          props: { account, type: _this.chartType },
        }
      },
    }),
    headerItem('Bots', 'bot', {
      cellClick: (account) => {
        _this.loadLogicsInformation(account._id)
      },
      cellClass: 'cursor-pointer font-semibold',
      generator: (account) => {
        return {
          value: _this.numberOfLogicById[account._id] || '-',
        }
      },
    }),
    headerItem('Position', 'position', {
      generator: (account) => {
        return {
          component: AccountPosition,
          props: { account },
        }
      },
    }),
    headerItem('Inital Amount', 'init', {
      generator: (account) => {
        return { value: formatNumber(account.depositAmount) }
      },
    }),
    headerItem('Balance', 'balance', {
      generator: (account) => {
        return { value: formatNumber(account.balance, '0') }
      },
    }),
    headerItem('Equity', 'equity', {
      generator: (account) => {
        return { value: formatNumber(account.equity, '0') }
      },
    }),
    headerItem('Entry price', 'entry-price', {
      generator: (account) => {
        const data = (account.positions || []).filter(position => {
          return position.volume > 0
        }).map((position) => {
          return `<div class="w-full mb-1">
            <span>${formatNumber(position.entryPrice)}</span>
          </div>`
        }).join('')
        return { value: `<div>${data}</div>` }
      },
    }),
    headerItem('Unrealised PNL', 'unrealised-pnl', {
      generator: (account) => {
        const data = (account.positions || []).filter(position => {
          return position.volume > 0
        }).map((position) => {
          let value = formatNumber(position.unrealizedPL)
          if (value === '0') {
            value = position.unrealizedPL
          }
          return `<div class="w-full">
            <span>${value}</span>
          </div>`
        }).join('')
        return { value: `<div>${data}</div>` }
      },
    }),
    headerItem('UID', 'uid', {
      generator: (account) => {
        if (account.productId) {
          return { value: `ProductID: ${account.productId}` }
        }
        let value = account.uid
        if (value.length < 15) {
          return { value }
        }
        value = `${value.slice(0, 5)}...${value.slice(-5)}`
        return { value }
      },
    }),
    headerItem('Exchange', 'exchange', {
      generator: (account) => {
        return { value: EXCHANGES[account.exchange] || account.exchange }
      },
    }),
    global.IS_MOBILE ? null : headerItem('Memo', 'memo', {
      generator: (account) => {
        return { value: account.memo || '' }
      },
    }),
    headerItem('Make order', 'make-order', {
      generator: (account) => {
        return {
          component: DBtn,
          props: {
            color: 'primary',
            size: 'xs',
            disabled: !_this.orderable(account),
            text: 'Order',
          },
          events: {
            click: () => {
              _this.order(account)
            },
          },
        }
      },
    }),
    global.IS_MOBILE ? null : headerItem('', 'edit', {
      generator: (account) => {
        return {
          component: EditAccount,
          props: {
            account,
            total: _this.group.total,
          },
          events: {
            onEdit: () => _this.editAccount(account),
            onDelete: () => _this.removeAccount(account),
            onMoveUp: () => _this.moveAccountUp(account),
            onMoveDown: () => _this.moveAccountDown(account),
          },
        }
      },
    }),
  ].filter(Boolean)
}
