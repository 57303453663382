import { header, headerItem } from '@/utils/table'
import { EXCHANGE_OPTIONS } from '@/common/constants.js'
import XIcon from '@/components/daisy/atoms/icons/index.vue'
import DInput from '@/components/daisy/atoms/input/index.vue'
import DSelect from '@/components/daisy/atoms/select/index.vue'
import { isHaveApiKey, isHaveToken } from '@/utils/exchange/index.js'

export default function headers(_this) {
  return [
    header('Account', 'name', _this),
    headerItem('Group', 'group', {
      class: 'min-w-48',
      generator: (item) => {
        return {
          component: DSelect,
          props: {
            value: item.group,
            items: _this.groupOptions,
          },
          events: {
            input: (value) => {
              _this.setValue(item.index, 'group', value)
            },
          },
        }
      },
    }),
    headerItem('Exchange', 'exchange', {
      class: 'min-w-48',
      generator: (item) => {
        return {
          component: DSelect,
          props: {
            value: item.exchange,
            items: EXCHANGE_OPTIONS,
          },
          events: {
            input: (value) => {
              _this.updateExchange(item.index, value)
            },
          },
        }
      },
    }),
    headerItem('ProductID', 'productId', {
      generator: (item) => {
        return {
          component: DInput,
          props: {
            disabled: !isHaveToken(item.exchange),
            value: item.productId,
            type: 'number',
          },
          events: {
            onChange: (value) => {
              _this.setValue(item.index, 'productId', parseInt(value))
            },
          },
        }
      },
    }),
    headerItem('Token', 'token', {
      generator: (item) => {
        return {
          component: DInput,
          props: {
            disabled: !isHaveToken(item.exchange),
            value: item.token,
          },
          events: {
            onChange: (value) => {
              _this.setValue(item.index, 'token', value)
            },
          },
        }
      },
    }),
    headerItem('API key', 'key', {
      generator: (item) => {
        return {
          component: DInput,
          props: {
            disabled: !isHaveApiKey(item.exchange),
            value: item.key,
          },
          events: {
            onChange: (value) => {
              _this.setValue(item.index, 'key', value)
            },
          },
        }
      },
    }),
    headerItem('Secret key', 'secret', {
      generator: (item) => {
        return {
          component: DInput,
          props: {
            disabled: !isHaveApiKey(item.exchange),
            value: item.secret,
          },
          events: {
            onChange: (value) => {
              _this.setValue(item.index, 'secret', value)
            },
          },
        }
      },
    }),
    header('Memo', 'memo', _this),
    headerItem('Delete', 'delete', {
      generator: (item) => {
        return {
          component: XIcon,
          props: {
            class: 'btn-error btn-circle btn-xs',
            name: 'x',
          },
          events: {
            click: () => {
              _this.removeInput(item.index)
            },
          },
        }
      },
    }),
  ]
}
