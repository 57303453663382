<template>
  <div class="max-w-full w-11/12 h-full flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <div class="font-medium text-lg">
        {{ $t('Batch update') }}
      </div>
      <div class="flex items-center justify-between">
        <div
          class="flex items-center justify-between space-x-2 mr-8"
          v-if="inputs.length > 1"
        >
          <span class="font-medium">
            {{ $t('Batch edit') }} ({{ inputs.length }})
          </span>
          <input
            v-model="isBatchEdit"
            type="checkbox"
            checked="checked"
            class="toggle border-solid toggle-primary"
          />
        </div>
        <button class="btn-sm-primary" @click.stop="addInput">
          {{ $t('Add') }}
        </button>
      </div>
    </div>
    <div class="flex-grow overflow-scroll border" :key="key">
      <d-table :headers="headers" :items="inputs" />
    </div>
    <div class="flex items-center justify-center space-x-4">
      <button class="btn-sm-primary" @click.stop="save">
        {{ $t('Save') }}
      </button>
      <button class="btn-sm-warning" @click="$$closeModal">
        {{ $t('Cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import batchMixin from '@/mixins/batch.js'
import headersGenerator from './headers.js'
import { credentialsActions } from '@/store/constants.js'

const _default = {
  name: '',
  exchange: '',
  token: '',
  productId: 0,
  key: '',
  secret: '',
  group: '',
  memo: '',
}

export default {
  name: 'batch-update-account',
  mixins: [batchMixin],

  computed: {
    ...mapGetters({
      groupOptions: 'credentials/groupOptions',
    }),
    headers() {
      return headersGenerator(this)
    },
    _default() {
      return _default
    },
  },

  methods: {
    updateExchange(index, value) {
      if (this.inputs.length > 1 && this.isBatchEdit) {
        this.inputs = this.inputs.map((el, index) => {
          return { ..._default, exchange: value, index }
        })
      } else {
        this.inputs[index] = { ..._default, exchange: value, index }
        this.key++
      }
    },
    async save(event) {
      await this.$http.post('/api/v2/account/batch', this.inputs)
      await this.$store.dispatch(
        `credentials/${credentialsActions.LOAD_CREDENTIALS}`,
        {
          force: true,
        }
      )
      this.$$closeModal(event)
    },
  },
}
</script>
