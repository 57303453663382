<template>
  <delete-modal :label="label" :handler="remove" @onClose="$emit('onClose')" />
</template>

<script>
import { credentialsActions } from '@/store/constants.js'
import DeleteModal from '@/components/misc/delete-modal/index.vue'

export default {
  name: 'delete-account',
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  components: {
    DeleteModal,
  },

  computed: {
    label() {
      return `
        <div class="font-medium text-lg">
          ${this.$t('Delete Account')}
          <span class="font-bold">${this.account.name}</span>
        </div>`
    },
  },

  methods: {
    async remove() {
      await this.$http.delete(`/api/v2/credentials/${this.account._id}`)
      await this.$store.dispatch(
        `credentials/${credentialsActions.LOAD_CREDENTIALS}`,
        {
          force: true,
        }
      )
      this.$emit('onClose')
    },
  },
}
</script>
